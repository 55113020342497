import React from "react";
import styles from "./home.module.css";

import { Header } from "./components/header.js";
import { Footer } from "./components/footer.js";

import Banner from "../../assets/banner.jpeg";
import Location from "../../assets/location.jpeg";

export function Home() {
    return (
        <>
            <Header />
            <main>
                <section id="home" class={styles.home}>
                    <div class={styles.info}>
                        <h1>Caboolture Badminton Club</h1>
                        <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Expedita odio porro ad. Expedita, soluta.
                            Nesciunt, labore quis incidunt autem voluptates
                            error alias mollitia harum dolores quasi praesentium
                            rerum repellendus sunt.
                        </p>
                    </div>
                    <img src={Banner} alt="Banner" />
                </section>
                <section id="hours" class={styles.hours}>
                    <h1>Opening Hours</h1>
                    <div class={styles.calendar}>
                        <div class={styles.day}>
                            <p>
                                <span>⏺︎</span>
                                <span>Mon</span>
                                <span>⏺︎</span>
                            </p>
                            <p>Closed</p>
                        </div>
                        <div class={styles.day}>
                            <p>
                                <span>⏺︎</span>
                                <span>Tue</span>
                                <span>⏺︎</span>
                            </p>
                            <p>Closed</p>
                        </div>
                        <div class={styles.day}>
                            <p>
                                <span>⏺︎</span>
                                <span>Wed</span>
                                <span>⏺︎</span>
                            </p>
                            <p>Closed</p>
                        </div>
                        <div class={styles.day}>
                            <p>
                                <span>⏺︎</span>
                                <span>Thu</span>
                                <span>⏺︎</span>
                            </p>
                            <p>6pm - 9pm</p>
                        </div>
                        <div class={styles.day}>
                            <p>
                                <span>⏺︎</span>
                                <span>Fri</span>
                                <span>⏺︎</span>
                            </p>
                            <p>Closed</p>
                        </div>
                        <div class={styles.day}>
                            <p>
                                <span>⏺︎</span>
                                <span>Sat</span>
                                <span>⏺︎</span>
                            </p>
                            <p>Closed</p>
                        </div>
                        <div class={styles.day}>
                            <p>
                                <span>⏺︎</span>
                                <span>Sun</span>
                                <span>⏺︎</span>
                            </p>
                            <p>6pm - 9pm</p>
                        </div>
                    </div>
                </section>
                <section id="location" class={styles.location}>
                    <h1>Where we Play</h1>
                    <div class={styles.contents}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d444.0206405633349!2d152.94701403409957!3d-27.08808891493872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b93e4acbe344bb9%3A0x11406d69b355288d!2sMoreton%20Bay%20Table%20Tennis%20Association!5e0!3m2!1sen!2sau!4v1719361790608!5m2!1sen!2sau"
                            llowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            title="Google Maps of location"
                        ></iframe>
                        <img src={Location} alt="Location of the club" />
                    </div>
                </section>
                <section id="membership" class={styles.membership}>
                    <h1>Membership</h1>
                    <div class={styles.cards}>
                        <div class={styles.card}>
                            <div>
                                <h2>Non-Member</h2>
                                <p>
                                    <span>$12</span>/night
                                </p>
                                <p>16 years old and over</p>
                            </div>

                            <p>Fee for Non-Members playing casual games</p>
                        </div>
                        <div class={styles.card}>
                            <div>
                                <h2>Member</h2>
                                <p>
                                    <span>$7</span>/night
                                </p>
                                <p>
                                    <span>$10</span>/annually
                                </p>
                                <p>16 years old and over</p>
                            </div>

                            <p>Fee for Members playing casual games</p>
                        </div>
                        <div class={styles.card}>
                            <div>
                                <h2>Junior</h2>
                                <p>
                                    <span>$4</span>/night
                                </p>
                                <p>Under 16 years old</p>
                            </div>

                            <p>Fee for under 16s playing casual games</p>
                        </div>
                        <a
                            href="http://www.qba.net.au/pages/indexp.asp?pageid=115"
                            target="_blank"
                            rel="noreferrer"
                            class={styles.card}
                        >
                            <div>
                                <h2>QBA Adult</h2>
                                <p>
                                    <span>$40</span>/annually
                                </p>
                                <p>16 years old and over</p>
                            </div>
                            <p>
                                Queensland Badminton Association fee for playing
                                in tournaments
                            </p>
                        </a>
                        <a
                            href="http://www.qba.net.au/pages/indexp.asp?pageid=115"
                            target="_blank"
                            rel="noreferrer"
                            class={styles.card}
                        >
                            <div>
                                <h2>QBA Junior</h2>
                                <p>
                                    <span>$30</span>/annually
                                </p>
                                <p>Under 16 years old</p>
                            </div>
                            <p>
                                Queensland Badminton Association fee for playing
                                in tournaments
                            </p>
                        </a>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
