import React from "react";
import styles from "./components.module.css";

import Logo from "../../../assets/logo.jpeg";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import { useAuthState } from "react-firebase-hooks/auth";

firebase.initializeApp({
    apiKey: "AIzaSyA8pjTEXcebMTJTJlYBNATlAgcYQs8iM1E",
    authDomain: "cab-badminton.firebaseapp.com",
    projectId: "cab-badminton",
    storageBucket: "cab-badminton.appspot.com",
    messagingSenderId: "577018892851",
    appId: "1:577018892851:web:5e6a07c56d89aace810946",
});

const auth = firebase.auth();

export function Header() {
    const [user] = useAuthState(auth);

    return (
        <header>
            <img src={Logo} alt="Logo" />
            <nav>
                <a href="/#home">Home</a>
                <a href="/#hours">Opening Hours</a>
                <a href="/#location">Location</a>
                <a href="/#membership">Membership</a>
            </nav>
            <a href="./signin" class={styles.signup}>
                {user ? "Dashboard" : "Join Us"}
            </a>
        </header>
    );
}
