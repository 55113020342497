import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./app.module.css";

import { Header } from "./components/header.js";
import { Footer } from "./components/footer.js";

import firebase from "firebase/compat/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

firebase.initializeApp({
    apiKey: "AIzaSyA8pjTEXcebMTJTJlYBNATlAgcYQs8iM1E",
    authDomain: "cab-badminton.firebaseapp.com",
    projectId: "cab-badminton",
    storageBucket: "cab-badminton.appspot.com",
    messagingSenderId: "577018892851",
    appId: "1:577018892851:web:5e6a07c56d89aace810946",
});

const auth = getAuth();

export function App() {
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    if (user) {
        return (
            <>
                <Header />
                <main>
                    <section id="app"></section>
                </main>
                <Footer />
            </>
        );
    } else {
        return navigate("/signin");
    }
}
