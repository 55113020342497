import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./app.module.css";

import { Footer } from "./components/footer.js";

import firebase from "firebase/compat/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

firebase.initializeApp({
    apiKey: "AIzaSyA8pjTEXcebMTJTJlYBNATlAgcYQs8iM1E",
    authDomain: "cab-badminton.firebaseapp.com",
    projectId: "cab-badminton",
    storageBucket: "cab-badminton.appspot.com",
    messagingSenderId: "577018892851",
    appId: "1:577018892851:web:5e6a07c56d89aace810946",
});

const auth = getAuth();

export function Signin() {
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: "select_account ",
        });
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log(result.user);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    if (!user) {
        return (
            <>
                <main>
                    <section id="signin" class={styles.signin}>
                        <div>
                            <h1>Sign In</h1>

                            <button onClick={signInWithGoogle}>
                                Sign In with Google
                            </button>
                        </div>
                    </section>
                </main>
                <Footer />
            </>
        );
    } else {
        return navigate("/app");
    }
}
